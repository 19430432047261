'use client'

import { useContext } from 'react'

import {
  AdServerConfigurationContext,
  type AdServerConfigurationContextValue,
} from './AdServerConfigurationContext'

export const useAdServerConfigurationContext =
  (): AdServerConfigurationContextValue => {
    const contextValue = useContext(AdServerConfigurationContext)

    if (!contextValue) {
      throw new Error('AdServerConfigurationContext is not defined')
    }

    return contextValue
  }
