import { type AxiosRequestConfig } from 'axios'
import deepmerge from 'deepmerge'

import { type PublicGlobalConfig } from '../../../global-config'
import {
  HTTP_HEADER_ACCEPT_LANGUAGE,
  HTTP_HEADER_CACHE_CONTROL,
} from '../../../http'

/**
 * To make a request to Bully some specific headers and other configuration must be set.
 * This function performs such configuration, and returns it.
 *
 * Configuration is done "per request", because it uses data from the incoming request.
 */
export const buildClientSideBullyRequestConfig = (
  /**
   * context getServerSideProps - will take configuration values and request from it.
   */
  globalConfig: PublicGlobalConfig,

  /**
   * extra options that will be merged and override default ones
   */
  overrideOptions?: AxiosRequestConfig,
): AxiosRequestConfig => {
  const requestConfig: AxiosRequestConfig = {
    // @ts-expect-error -- needed for runtime check if request config was passed
    clientRequestConfigPassed: true,
    headers: {
      [HTTP_HEADER_ACCEPT_LANGUAGE]: globalConfig.locale,
      [HTTP_HEADER_CACHE_CONTROL]: 'no-cache',
    },
  }

  if (overrideOptions) {
    return deepmerge(requestConfig, overrideOptions)
  }

  return requestConfig
}
