'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type PublicFeatureToggles } from '../model/FeatureToggles'

const FeatureToggleContext = createContext<PublicFeatureToggles>({})

export const useFeatureToggleContext = (): PublicFeatureToggles =>
  useContext(FeatureToggleContext)

type FeatureToggleProviderProps = {
  featureToggles?: PublicFeatureToggles
}

export const FeatureToggleProvider: FC<
  PropsWithChildren<FeatureToggleProviderProps>
> = (props) => {
  const { children, featureToggles = {} } = props

  return (
    <FeatureToggleContext.Provider value={featureToggles}>
      {children}
    </FeatureToggleContext.Provider>
  )
}
