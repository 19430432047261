import { apmCaptureError, apmSetCustomContext } from '../apm'
import { getTenantLocalesMap } from '../global-config'
import { type LOCALE } from '../i18n'
import { type TenantLanguage } from '../tenant'

/**
 * takes url and returns only the path without the host
 *
 * https://shop-apotheke.com/artikel/upm3KWD3G/ -> /artikel/upm3KWD3G/
 */
export const urlGetPathName = (fullUrl: string): string => {
  if (!fullUrl) {
    return ''
  }
  try {
    const url = new URL(fullUrl)

    return url.pathname
  } catch {
    return ''
  }
}

/**
 * Check if the url given is a valid url
 */
export const urlIsValid = (url: string): boolean => {
  try {
    return Boolean(new URL(url))
  } catch {
    return false
  }
}

/**
 * Extracts the relative url from the canonical url. For Next.js we use
 * relative link to navigate between Next.js pages
 */
export const urlGetRelativeUrl = (url: string): string => {
  const formatedUrl = new URL(url, 'https://shop-apotheke.com')

  return formatedUrl.href.slice(formatedUrl.origin.length)
}

const getUriParts = (
  uri: string,
): Pick<URL, 'hash' | 'pathname' | 'search'> => {
  try {
    const url = new URL(uri, 'https://localhost')

    const { hash, pathname, search } = url

    return { hash, pathname, search }
  } catch (error) {
    /* istanbul ignore next `instanceof` is not working correct https://github.com/jestjs/jest/issues/2549 */
    if (error instanceof Error) {
      apmSetCustomContext({ uri })
      apmCaptureError(error)

      return {
        hash: '',
        pathname: '/',
        search: '',
      }
    }

    /* istanbul ignore next */
    throw error
  }
}

const addTrailingSlashToPathname = (pathname: string): string => {
  if (pathname.endsWith('/')) {
    return pathname
  }

  if (pathname.split('/').at(-1)?.includes('.')) {
    return pathname
  }

  return `${pathname}/`
}

/**
 * Contains languages only for multilingual tenants
 */
const getMultilingualLocaleLanguages: () => Partial<
  Record<LOCALE, TenantLanguage>
> = () =>
  Object.fromEntries(
    Object.values(getTenantLocalesMap()).flatMap((languageRecord) => {
      const languageEntries = Object.entries(languageRecord)

      // Not needed for single language tenant
      if (languageEntries.length === 1) {
        return []
      }

      return languageEntries.map(([language, locale]) => [locale, language])
    }),
  )

const addLanguagePrefixToPathname = (
  pathname: string,
  locale: LOCALE,
): string => {
  const language = getMultilingualLocaleLanguages()[locale]

  if (!language || pathname.startsWith(`/${language}/`)) {
    return pathname
  }

  return `/${language}${pathname}`
}

type NormalizeUriParams = {
  locale?: LOCALE
  uri?: string
}

/**
 * Normalize URIs coming from some API responses:
 *
 *  1. Add leading slash: `test/?test=1` -> `/test/?test=1`
 *  2. Add trailing slash: `/test` -> `/test/`
 *  3. Add language prefix if `locale` provided for multilingual tenants if needed: `/test/` -> `/fr/test/`
 */
export const urlNormalizeUri = (params: NormalizeUriParams): string => {
  const { locale, uri = '' } = params

  if (process.env.NODE_ENV === 'development' && !uri) {
    // eslint-disable-next-line no-console -- notify developer for empty uri handling
    console.trace('No uri provided, please handle it in the caller function')
  }

  const uriParts = getUriParts(uri)

  let { pathname } = uriParts
  const { hash, search } = uriParts

  pathname = addTrailingSlashToPathname(pathname)

  if (locale) {
    pathname = addLanguagePrefixToPathname(pathname, locale)
  }

  return `${pathname}${search}${hash}`
}

/**
 * replaces schema and host of the inputUrl with the schema and host of the baseUrl
 * @param inputUrl
 * @param originToReplaceWith
 */
export const urlReplaceOrigin = (
  inputUrl: string,
  originToReplaceWith: string,
): string => {
  try {
    // Attempt to parse the input URL. This will throw if inputUrl is not a valid full URL.
    const url = new URL(inputUrl, 'http://localhost')

    // If parsing succeeds, replace the protocol and host with those from baseUrl.
    const base = new URL(originToReplaceWith)
    url.protocol = base.protocol
    url.host = base.host

    return url.toString()
  } catch {
    // If parsing fails, return the input URL unchanged.
    return inputUrl
  }
}

/**
 * returns a tuple of first part and rest.
 * useful for e.g. splitting tenant or language from rest
 * '/com/foo/bar': ['/com', '/foo/bar']
 * '/com/de/bar': ['/com', '/de/bar']
 * '/de/bar': ['/de', '/bar']
 * '': ['', ''],
 * '/': ['/', ''],
 * '/test': ['/test', ''],
 * '/test/': ['/test', '/'],
 */
export const urlSplitToHeadAndTail = (url: string): [string, string] => {
  // Find the index of the second '/' (the first non-zero index '/')
  const secondSlashIndex = url.indexOf('/', 1)

  // If there is no second '/', split the URL accordingly
  if (secondSlashIndex === -1) {
    return [url, '']
  }

  // Slice the URL at the second '/'
  const head = url.slice(0, secondSlashIndex)
  const tail = url.slice(secondSlashIndex)

  return [head, tail]
}

/**
 * URL instance to string without host
 * example:
 * const urlInstance = new URL('https://shop-apotheke.com/foo/bar?baz=qux#quux')
 * urlInstanceToStringWithoutHost(urlInstance) // '/foo/bar?baz=qux#quux'
 */
export const urlInstanceToUriString = (url: URL): string => {
  return url.pathname + url.search + url.hash
}

/**
 * urlAddSearchParams('/foo', {a: 'b'}) // /foo?a=b
 * urlAddSearchParams('/foo?b=a', {c: 'd'}) // /foo?a=b&c=d
 */
export const urlAddSearchParams = (
  url: string,
  params: Record<string, string>,
): string => {
  // Define a fallback base URL to handle relative URLs
  const fallbackBase = 'https://fallback'
  const parsedUrl = new URL(url, fallbackBase)

  // Determine if the original URL had host or started with pathname only
  const hasNoHost = parsedUrl.origin === new URL(fallbackBase).origin

  // Append each search parameter
  for (const [key, value] of Object.entries(params)) {
    parsedUrl.searchParams.append(key, value)
  }

  if (hasNoHost) {
    // Return only the pathname and search for relative URLs
    return parsedUrl.pathname + parsedUrl.search
  }

  // Return the full URL for absolute URLs
  return parsedUrl.toString()
}
