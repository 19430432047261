import { useCallback } from 'react'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { urlReplaceOrigin } from '../../url-handling/url'
import { useExperimentsContext } from '../components/ExperimentsContext'

import { useCdnStaticFileHost } from './useCdnStaticFileHost'

/**
 * @return returns a function that will REPLACE IF PRESENT OR PREFIX the origin (schema + host)
 * of the url with the cdn origin
 *
 * @example:
 *```tsx
 * const { addStaticCdnHostToUrl } = useCdnAddStaticFileHost()
 *
 * <Image
 *    src={addStaticCdnHostToUrl('/some-image.jpg')} // will result in 'http://some-akamai.com/some-image.jpg
 *    src={addStaticCdnHostToUrl('http://any-host.com/some-image.jpg')} // will replace any-host.com with the cdn origin
 * />
 * ```
 *
 *
 * IMPORTANT ON EXPERIMENT CLEANUP
 * this shall be moved to cdn domain, so for future the cdn is always injectable
 */
export const useExperimentalAddStaticFileHost = (): {
  addStaticCdnHostToUrl: (url?: string) => string
} => {
  const { experiments } = useExperimentsContext()
  const { tenant } = useGlobalConfigContext()
  const cdnOrigin = useCdnStaticFileHost(experiments, tenant)

  return {
    addStaticCdnHostToUrl: useCallback(
      (originalUrl?: string) => {
        if (!originalUrl) {
          return '/'
        }

        return urlReplaceOrigin(originalUrl, cdnOrigin)
      },
      [cdnOrigin],
    ),
  }
}
