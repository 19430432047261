import { type AxiosInstance } from 'axios'

import { type Configuration } from '@redteclab/api/clients/bully'

import { useGlobalConfigContext } from '../../../global-config'

import {
  type AxiosClientsideInterceptors,
  buildClientSideBullyAxiosInstance,
} from './buildClientSideBullyAxiosInstance'

let clientSideApiClientCache: Map<unknown, unknown> | null = null
let clientSideApiClientWithCacheablePath: Map<unknown, unknown> | null = null

const getApiClientCache = (
  withCacheablePrefix?: boolean,
): Map<unknown, unknown> => {
  if (withCacheablePrefix) {
    if (clientSideApiClientWithCacheablePath === null) {
      clientSideApiClientWithCacheablePath = new Map()
    }

    return clientSideApiClientWithCacheablePath
  }

  if (clientSideApiClientCache === null) {
    clientSideApiClientCache = new Map()
  }

  return clientSideApiClientCache
}

/**
 * CLIENTSIDE HOOK
 *
 * This function retrieves bully api clients generated from API Contracts (@redteclab/api)
 * and caches them, so the same instance is reused.
 *
 * @example
 * ```
 * const apiClient = useBullyApiClient(SomeGeneratedControllerFactory)
 * apiClient.someMethod(...)
 * ```
 */
export const useBullyApi = <
  T extends (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
  ) => ReturnType<T>,
>(
  apiClientFactory: T,
  options?: {
    overrideInterceptors?: AxiosClientsideInterceptors
    withCacheablePrefix?: boolean
  },
): ReturnType<T> => {
  const publicGlobalConfig = useGlobalConfigContext()

  const apiInstanceCache = getApiClientCache(options?.withCacheablePrefix)
  const cachedInstance = apiInstanceCache.get(apiClientFactory) as
    | ReturnType<T>
    | undefined
  if (cachedInstance && !options?.overrideInterceptors) {
    return cachedInstance
  }

  const newInstance = apiClientFactory(
    undefined,
    options?.withCacheablePrefix
      ? `${publicGlobalConfig.bullyUrl}/cacheable`
      : publicGlobalConfig.bullyUrl,
    buildClientSideBullyAxiosInstance(
      publicGlobalConfig,
      options?.overrideInterceptors,
    ),
  )

  if (!options?.overrideInterceptors) {
    apiInstanceCache.set(apiClientFactory, newInstance)
  }

  return newInstance
}
