import { useReadLocalStorage } from 'usehooks-ts'

import { type NowInfoStorage } from './context/nowGeneralContext'

export const useNowGetZipCodeFromLocalStorage = (): string | undefined => {
  // `useReadLocalStorage` automatically `JSON.parse` `localStorage` item value
  const nowInfo = useReadLocalStorage<NowInfoStorage>('nowInfo')

  return nowInfo?.zipSupportInfo?.zip
}
