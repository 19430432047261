'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type DeviceDetectorInfoType } from '../deviceDetectorAccessor'

const DeviceDetectorContext = createContext<DeviceDetectorInfoType>({
  deviceType: 'desktop',
  isDesktop: true,
  isMobile: false,
  isTablet: false,
})

/**
 * Device detector context provider, app supposed to have only one such context.
 */
export const DeviceDetectorContextProvider: FC<
  PropsWithChildren<{ deviceInfo: DeviceDetectorInfoType }>
> = ({ children, deviceInfo }) => {
  return (
    <DeviceDetectorContext.Provider value={deviceInfo}>
      {children}
    </DeviceDetectorContext.Provider>
  )
}

/**
 * For using the context value in consumer components.
 */
export const useDeviceDetectorContext = (): DeviceDetectorInfoType => {
  return useContext(DeviceDetectorContext)
}
