import { ExperimentPageType } from '../experimentPageType'

import {
  EXPERIMENT_NAME_CONTACT_PAGE_REDESIGN_ABC_TEST,
  EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW,
  EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST,
  EXPERIMENT_NAME_HOME_ONE_ACCOUNT,
} from './EXPERIMENT_NAME'
import {
  EXPERIMENT_BREADCRUMBS,
  EXPERIMENT_EXIT_INTENT_POPUP,
} from './EXPERIMENT_NAME_PER_TENANT'
import { type TenantBasedExperiment } from './Experiment.types'

export const EXPERIMENTS_TO_ACTIVATE_PER_PAGE: Record<
  string,
  (TenantBasedExperiment | string)[]
> = {
  [ExperimentPageType.Account]: [EXPERIMENT_NAME_HOME_ONE_ACCOUNT],
  [ExperimentPageType.Contact]: [
    EXPERIMENT_NAME_CONTACT_PAGE_REDESIGN_ABC_TEST,
  ],
  [ExperimentPageType.Default]: [
    EXPERIMENT_EXIT_INTENT_POPUP,
    EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST,
  ],
  [ExperimentPageType.Product]: [
    EXPERIMENT_BREADCRUMBS,
    EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW,
  ],
}
