import axios, {
  type AxiosInstance,
  type InternalAxiosRequestConfig,
} from 'axios'

import { type PublicGlobalConfig } from '../../../global-config'

type AxiosInterceptorParams = Parameters<
  (typeof axios)['interceptors']['request']['use']
>

/**
 * When calling bully endpoint through, some headers must be always added (e.g. authorization).
 * this is done via passing {@link buildClientSideBullyRequestConfig} to api client request.
 *
 * Because it is not possible to ensure it via types (because they are generated), we do runtime check in development.
 */
const interceptorEnsureClientSideBullyRequestConfigWasPassed: AxiosInterceptorParams =
  [
    (config): InternalAxiosRequestConfig => {
      if (
        !(config as unknown as Record<string, boolean>)
          .clientRequestConfigPassed
      ) {
        throw new Error(`you did not pass buildClientSideBullyRequestConfig when making api call for ${
          config.url ?? ''
        }:
      example useBullyApi(
        MyApiFactory
      ).myRequestGet(
        someArg,
        buildClientSideBullyRequestConfig({ privateGlobalConfig, req }) <--- this is missing
      )`)
      }

      return config
    },
    undefined,
  ]

export type AxiosClientsideInterceptors = {
  custom?: AxiosInterceptorParams
  ensureRequestConfigPassed?: AxiosInterceptorParams
}

const bullyClientDefaultInterceptors: AxiosClientsideInterceptors = {
  custom: undefined,
  ensureRequestConfigPassed:
    interceptorEnsureClientSideBullyRequestConfigWasPassed,
}

const getInterceptors = (
  overrideInterceptors: AxiosClientsideInterceptors,
  publicGlobalConfig: PublicGlobalConfig,
): AxiosInterceptorParams[] => {
  const interceptorsToUse = {
    ...bullyClientDefaultInterceptors,
    ...overrideInterceptors,
  }

  if (publicGlobalConfig.deploymentStage !== 'development') {
    delete interceptorsToUse.ensureRequestConfigPassed
  }

  return Object.values(interceptorsToUse).filter(Boolean)
}

/**
 * Build axios instance for bully api clients, with default configs.
 * Instance supposed to be reused so do not put any "per request" logic in here.
 */
export const buildClientSideBullyAxiosInstance = (
  publicGlobalConfig: PublicGlobalConfig,
  overrideInterceptors: AxiosClientsideInterceptors = {},
): AxiosInstance => {
  const instance = axios.create()

  for (const it of getInterceptors(overrideInterceptors, publicGlobalConfig)) {
    instance.interceptors.request.use(...it)
  }

  return instance
}
