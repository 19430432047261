import { type FC } from 'react'
import { JsonLd } from 'react-schemaorg'

import { useSeoGlobalConfigContext } from './context/seoGlobalConfigContext'

export const SeoGlobalJsonLd: FC = () => {
  const seoConfig = useSeoGlobalConfigContext()

  return <JsonLd item={seoConfig.globalJsonLd} />
}
