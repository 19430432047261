'use client'

import { createContext, type FC, type ReactNode, useContext } from 'react'

import { type SeoGlobalConfig } from '../../meta-tags/SeoGlobalPerTenantConfiguration'

export type SeoGlobalConfigContextValue = SeoGlobalConfig

const SeoGlobalConfigContext =
  createContext<SeoGlobalConfigContextValue | null>(null)

export const SeoGlobalConfigProvider: FC<{
  children: ReactNode
  value: SeoGlobalConfig
}> = ({ children, value }) => {
  return (
    <SeoGlobalConfigContext.Provider value={value}>
      {children}
    </SeoGlobalConfigContext.Provider>
  )
}

export const useSeoGlobalConfigContext = (): SeoGlobalConfigContextValue => {
  const context = useContext(SeoGlobalConfigContext)
  if (context === null) {
    throw new Error(
      'useSeoGlobalConfigContext must be used within a SeoGlobalConfigContextProvider',
    )
  }

  return context
}
