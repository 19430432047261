'use client'

import { usePathname } from 'next/navigation'
import Script from 'next/script'
import {
  type AwaitedReactNode,
  type FC,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { type ClientSessionCustomerData } from '@redteclab/api/clients/bully'

import { useGlobalConfigContext } from '../../../global-config'
import { useRunCallbackWhenSessionCallIsDone } from '../../../user-data'
import {
  adServerSlotsPushInitCommand,
  adServerSlotsPushSyncUserCommand,
} from '../../adServerSlots'
import { type AdServerTargeting } from '../../getAdServerTargeting'

import {
  AdServerConfigurationContext,
  type AdServerConfigurationContextValue,
} from './AdServerConfigurationContext'
import { AdServerSlotsCleanup } from './AdServerSlotsCleanup'
import { useAdServerState } from './useAdServerState'

type AdServerConfigurationContextProviderProps = Pick<
  AdServerConfigurationContextValue,
  'adServerConfig'
> & {
  adServerTargeting: AdServerTargeting
  children?: Exclude<ReactNode, Promise<AwaitedReactNode>>
}

export const AdServerConfigurationContextProvider: FC<
  AdServerConfigurationContextProviderProps
> = ({ adServerConfig, adServerTargeting, children }) => {
  const { language, tenantAndEnv } = useGlobalConfigContext()
  const { getBanner, initBanners } = useAdServerState()

  useRunCallbackWhenSessionCallIsDone(
    useCallback((sessionData?: ClientSessionCustomerData) => {
      if (!sessionData) {
        return
      }

      adServerSlotsPushSyncUserCommand(sessionData)
    }, []),
  )

  const pathname = usePathname()

  useEffect(() => {
    const initialized = window._slots?.some(
      ([commandName]) => commandName === 'init',
    )

    if (!initialized && pathname) {
      adServerSlotsPushInitCommand({
        language,
        tenantAndEnv,
      })
    }
  }, [language, pathname, tenantAndEnv])

  const value = useMemo(
    () => ({
      adServerConfig,
      adServerTargeting,
      getBanner,
      initBanners,
    }),
    [adServerConfig, adServerTargeting, getBanner, initBanners],
  )

  return (
    <AdServerConfigurationContext.Provider value={value}>
      {/* Put `AdServerSlotsCleanup` first to clean up slots on `pathname` change before other `useEffect`'s take place */}
      <AdServerSlotsCleanup />
      {children}
      <Script src={adServerConfig.adServerClientSrc} strategy="lazyOnload" />
    </AdServerConfigurationContext.Provider>
  )
}
