'use client'

import { useEffect } from 'react'

import { type ClientSessionCustomerData } from '@redteclab/api/clients/bully'

import { useUserApiSwrSessionV1Customer } from './api/swr/useUserApiSwrSessionV1Customer'

/**
 * this callback will run whenever user session swr returns new data.
 */
export const useRunCallbackWhenSessionCallIsDone = (
  callback: (data?: ClientSessionCustomerData) => void,
): void => {
  const sessionDataSwr = useUserApiSwrSessionV1Customer()

  useEffect(() => {
    if (!sessionDataSwr.data) {
      return
    }

    callback(sessionDataSwr.data)
  }, [callback, sessionDataSwr.data])
}
