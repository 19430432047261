import { type FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { JsonLd } from 'react-schemaorg'
import {
  type BreadcrumbList,
  type ListItem,
  type WithContext,
} from 'schema-dts'

import { useGlobalConfigContext } from '../../global-config'
import { urlNormalizeUri } from '../../url-handling'

export type SeoBreadcrumbListItem = Pick<ListItem, 'item' | 'name'>

/**
 * Only string `name` and URL `item` (except last item https://developers.google.com/search/docs/appearance/structured-data/breadcrumb#list-item) are required,
 * rest is provided by `SeoBreadcrumbListJsonLd` component
 */
export type SeoBreadcrumbListItems = [
  ...Required<SeoBreadcrumbListItem>[],
  SeoBreadcrumbListItem,
]

type SeoBreadcrumbListJsonLdProps = {
  breadcrumbs?: SeoBreadcrumbListItems
}

export const SeoBreadcrumbListJsonLd: FC<SeoBreadcrumbListJsonLdProps> = ({
  breadcrumbs,
}) => {
  const intl = useIntl()
  const globalConfig = useGlobalConfigContext()

  const itemListElement = useMemo(
    () =>
      [
        {
          item: new URL(
            urlNormalizeUri({ locale: globalConfig.locale, uri: '/' }),
            globalConfig.shopUrl,
          ).toString(),
          name: intl.formatMessage({ id: 'header.link.label.home' }),
        } satisfies SeoBreadcrumbListItem,
        ...(breadcrumbs ?? []),
      ].map<ListItem>((listItem, index) => ({
        ...listItem,
        '@type': 'ListItem',
        position: index + 1,
      })),
    [breadcrumbs, globalConfig, intl],
  )

  const item = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  } satisfies WithContext<BreadcrumbList>

  return <JsonLd<BreadcrumbList> item={item} />
}
