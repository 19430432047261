import Head from 'next/head'
import { Fragment, type JSX, type PropsWithChildren } from 'react'

import { useAppRouterMigrationContext } from './AppRouterMigrationProvider'

/**
 * Conditional switching between Pages Router `Head` (https://nextjs.org/docs/pages/api-reference/components/head) and
 * React Canary metadata https://react.dev/reference/react-dom/components/meta support in App Router.
 */
export const AppRouterMigrationHead = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { isAppRouter } = useAppRouterMigrationContext()

  const HeadComponent = isAppRouter ? Fragment : Head

  return <HeadComponent>{children}</HeadComponent>
}
