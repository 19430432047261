import { getCookie, type OptionsType, setCookie } from 'cookies-next/client'

export const clientSideCookieSet = (
  key: string,
  value: string,
  options?: OptionsType,
): void => {
  const defaultOptions = {
    path: '/',
  }

  setCookie(key, value, {
    ...defaultOptions,
    ...options,
  })
}

export const clientSideCookieGet = (key: string): string | undefined => {
  return getCookie(key) as unknown as string | undefined
}

const COOKIE_NAME_AD_STORAGE = 'ad_storage'
const COOKIE_NAME_USER_CONSENT = 'usercentrics_consent'

const clientSideCookieGetUserConsent = (): Record<
  string,
  boolean | undefined
> => {
  const consentCookie = clientSideCookieGet(COOKIE_NAME_USER_CONSENT)

  if (typeof consentCookie !== 'string') {
    return {}
  }

  try {
    return JSON.parse(consentCookie) as Record<string, boolean | undefined>
  } catch {
    return {}
  }
}

export const clientSideCookieGetUserConsentForAdStorage = (): boolean => {
  const consent = clientSideCookieGetUserConsent()

  return consent[COOKIE_NAME_AD_STORAGE] === true
}
