'use client'

import { sendGTMEvent } from '@next/third-parties/google'
import axios from 'axios'
import { useEffect, useRef } from 'react'
import useSWR from 'swr'

import { SiteType } from '@redteclab/gtm-datalayer-library'

import { useNowGetZipCodeFromLocalStorage } from '../now'
import { urlAddSearchParams } from '../url-handling'

import { type GtmDataProps } from './model/GtmDataProps'
import { type GtmEventData } from './model/gtmTypes'

/**
 * On some pages now ZIP code is needed to be added to a GTM URL that will be called
 * to retrieve the GTM data to push to `dataLayer`. It appends the user's ZIP code
 * from `localStorage` to the GTM event data.
 * https://jira.shop-apotheke.com/browse/WSTRA-9891
 */
const useAddZipCode = (originalGtmApiUrl?: string): string | undefined => {
  const zipCode = useNowGetZipCodeFromLocalStorage()

  if (
    !originalGtmApiUrl ||
    !zipCode ||
    (!originalGtmApiUrl.includes(SiteType.PDP) &&
      !originalGtmApiUrl.includes('zipCode='))
  ) {
    return originalGtmApiUrl
  }

  return urlAddSearchParams(originalGtmApiUrl, { zipCode })
}

const gtmApiUrlFetcher = async (url: string): Promise<GtmEventData> => {
  const { data } = await axios.get<GtmEventData>(url)

  return data
}

const useFetchGtmDataAndPushToDataLayer = ({
  gtmApiUrl,
}: GtmDataProps): void => {
  const gtmApiUrlWithZipCode = useAddZipCode(gtmApiUrl)

  const { data } = useSWR(gtmApiUrlWithZipCode, gtmApiUrlFetcher)

  useEffect(() => {
    if (data) {
      sendGTMEvent(data)
    }
  }, [data])
}

/**
 * Hook that:
 * * would push any gtm data on change (e.g. between page navigations).
 * * would make a call to provided url if present and push contents of response to gtm data layer.
 */
export const usePushGtmDataPropsToGtmDataLayer = ({
  gtmApiUrl,
  gtmDataToPush,
}: GtmDataProps = {}): void => {
  /**
   * if gtmApi url present this will fetch data from backend on client, and push to layer
   */
  useFetchGtmDataAndPushToDataLayer({ gtmApiUrl })

  /**
   * Use ref to keep previous data to avoid double pushing to GTM (in `<StrictMode>` mostly)
   */
  const previouslyPushed = useRef<GtmEventData[] | undefined>(undefined)

  useEffect(() => {
    if (!gtmDataToPush) {
      return
    }

    /**
     * if present will push to dataLayer
     */
    if (gtmDataToPush !== previouslyPushed.current) {
      for (const data of gtmDataToPush) {
        sendGTMEvent(data)
      }
      previouslyPushed.current = gtmDataToPush
    }
  }, [gtmDataToPush])
}
