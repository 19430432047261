'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

type Expa = string | null

const ExpaContext = createContext<Expa>(null)

export const useExpaContext = (): Expa => useContext(ExpaContext)

type ExpaProviderProps = PropsWithChildren<{
  expa?: Expa
}>

export const ExpaContextProvider: FC<ExpaProviderProps> = ({
  children,
  expa = null,
}) => <ExpaContext.Provider value={expa}>{children}</ExpaContext.Provider>
