/*
 * Next.js provides polyfills https://github.com/vercel/next.js/blob/canary/packages/next-polyfill-nomodule/src/index.js
 * but for better Developer Experience we provide more of them
 */
import 'core-js/features/array/to-sorted'
import 'core-js/features/global-this'
import 'core-js/features/string/at'
import 'core-js/features/string/replace-all'
import 'intersection-observer'
import './web.crypto.randomUUID'
// Support old browsers like Safari 11
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-pluralrules/locale-data/fr'
import '@formatjs/intl-pluralrules/locale-data/it'
import '@formatjs/intl-pluralrules/locale-data/nl'
