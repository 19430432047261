import { type FormatXMLElementFn } from 'intl-messageformat'
import { type JSX, type ReactNode } from 'react'

const defaultElements = [
  'b',
  'br',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'i',
  'li',
  'ol',
  'p',
  'strong',
  'sup',
  'u',
  'ul',
] satisfies (keyof JSX.IntrinsicElements)[]

const selfClosingTags = new Set(['br'])

export const htmlElements: Record<
  string,
  FormatXMLElementFn<ReactNode>
> = Object.fromEntries(
  defaultElements.map((Element) => [
    Element,
    (parts): JSX.Element =>
      selfClosingTags.has(Element) ? (
        <Element key={Element} />
      ) : (
        // Add `key` for rich text element to avoid React 19 warning about unique keys
        <Element key={Element}>{parts}</Element>
      ),
  ]),
)
