'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { experimentsApiActivateClientSide } from '../api/experimentsApi'
import { type ExperimentType } from '../model/Experiment.types'

type ExperimentContextType = {
  addExperimentKeyToActivate: (
    newKey: ExperimentType['name'] | undefined,
  ) => void
  experiments: ExperimentType[]
  pageType: string
}

const ExperimentContext = createContext<ExperimentContextType>({
  addExperimentKeyToActivate: () => {
    throw new Error('ExperimentsContext is not initialized')
  },
  experiments: [],
  pageType: 'default',
})

export const useExperimentsContext = (): ExperimentContextType =>
  useContext(ExperimentContext)

type ExperimentsProviderProps = {
  experiments?: ExperimentType[]
  pageType: string
}

export const ExperimentsProvider: FC<
  PropsWithChildren<ExperimentsProviderProps>
> = (props) => {
  const { children, experiments = [], pageType } = props

  const [experimentKeysToActivate, setExperimentKeysToActivate] = useState<
    ExperimentType['name'][]
  >([])

  const globalConfig = useGlobalConfigContext()

  useEffect(() => {
    const filteredExperimentKeys = experimentKeysToActivate.filter((key) =>
      experiments.some((experiment) => experiment.name === key),
    )

    if (filteredExperimentKeys.length === 0) {
      return
    }

    experimentsApiActivateClientSide({
      experiments: filteredExperimentKeys,
      language: globalConfig.language,
      tenantAndEnv: globalConfig.tenantAndEnv,
    })
  }, [
    experimentKeysToActivate,
    experiments,
    globalConfig.language,
    globalConfig.tenantAndEnv,
  ])

  const addExperimentKeyToActivate = useCallback(
    (newKey: ExperimentType['name'] | undefined): void => {
      if (newKey) {
        setExperimentKeysToActivate((oldKeys) => {
          if (oldKeys.includes(newKey)) {
            return oldKeys
          }

          return [...oldKeys, newKey]
        })
      }
    },
    [],
  )

  const contextValues = useMemo(
    () => ({
      addExperimentKeyToActivate,
      experiments,
      pageType,
    }),
    [experiments, addExperimentKeyToActivate, pageType],
  )

  return (
    <ExperimentContext.Provider value={contextValues}>
      {children}
    </ExperimentContext.Provider>
  )
}
