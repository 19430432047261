import axios from 'axios'

import { type PublicGlobalConfig } from '../../global-config/config-types/PublicGlobalConfig'
import { gtmDataLayerPush } from '../../gtm/gtmDataLayer'
import { type TenantLanguage } from '../../tenant/tenantLanguagesConfig'
import { type TENANT_AND_ENV } from '../../tenant/tenantTypes'
import { EXPERIMENT_SERP_PRE_QUERY_SUGGESTIONS } from '../model/EXPERIMENT_NAME_PER_TENANT'
import { type ExperimentType } from '../model/Experiment.types'
import {
  experimentAccessorGetExperimentNamePerTenant,
  experimentAccessorIsPreQuerySuggestionEnabled,
} from '../model/experimentAccessor'

/**
 * The function we need for special cases, when the activation
 * should happen only on event or, for example, if a certain
 * variant is selected.
 */
export const experimentsApiActivateClientSide = ({
  experiments,
  language,
  tenantAndEnv,
}: {
  experiments: string[]
  language: TenantLanguage
  tenantAndEnv: TENANT_AND_ENV
}): void => {
  if (typeof window === 'undefined') {
    throw new TypeError('This function should be called only on client side')
  }

  void axios
    .post(`/webclient/experiments/${tenantAndEnv}/${language}/activate`, {
      featureKeys: experiments,
    })
    .then(() => {
      gtmDataLayerPush({
        event: 'exp_activated',
        exp_names: experiments,
      })
    })
    .catch(() => {
      // do not care about activation result
    })
}

export const experimentsApiActivatePreQuerySuggestionIfEnabled = ({
  experiments,
  globalConfig,
  isMobile,
}: {
  experiments: ExperimentType[]
  globalConfig: PublicGlobalConfig
  isMobile: boolean
}): void => {
  const experimentName = experimentAccessorGetExperimentNamePerTenant({
    experimentName: EXPERIMENT_SERP_PRE_QUERY_SUGGESTIONS,
    isMobile,
    tenant: globalConfig.tenant,
  })

  if (
    !experimentAccessorIsPreQuerySuggestionEnabled(
      isMobile,
      globalConfig,
      experiments,
    ) ||
    !experimentName
  ) {
    return
  }

  const params = {
    experiments: [experimentName],
    language: globalConfig.language,
    tenantAndEnv: globalConfig.tenantAndEnv,
  }

  experimentsApiActivateClientSide(params)
}
