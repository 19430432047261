'use client'

import '../polyfills'

import { type JSX, type PropsWithChildren } from 'react'

import { AdServerConfigurationContextProvider } from '../ad-server'
import { CustomSwrConfig } from '../base-api-communication'
import { DeviceDetectorContextProvider } from '../device-detector'
import { ExpaContextProvider } from '../expa'
import { ExperimentsActivateGlobalExperiments } from '../experiments/components/ExperimentsActivateGlobalExperiments'
import { ExperimentsProvider } from '../experiments/components/ExperimentsContext'
import { FeatureToggleProvider } from '../feature-toggles'
import { GlobalConfigContextProvider } from '../global-config'
import { GtmProvider } from '../gtm'
import { I18nProvider } from '../i18n'
import { SaeConversationalChat } from '../sae-conversational-chat'
import {
  SeoAppMetaAndLinkTags,
  SeoBreadcrumbListJsonLd,
  SeoGlobalConfigProvider,
  SeoGlobalJsonLd,
} from '../seo'
import { useHintHydrationReadyForEndToEndTest } from '../testing-base'
import { WebviewContextProvider } from '../webview'

import { type AppPageProps } from './getServerSideAppProps'

/**
 * Wrapper for every application's page (App & Pages routers). In place (`pages/_app.tsx` & `app/layout.tsx`)
 * add router specific functionality.
 */
export const AppLayout = (
  props: PropsWithChildren<AppPageProps>,
): JSX.Element => {
  useHintHydrationReadyForEndToEndTest()

  const {
    adServerConfig,
    adServerTargeting,
    breadcrumbs,
    children,
    deviceInfo,
    expa,
    experiments,
    gtmConfig,
    gtmDataProps,
    isWebview,
    localeMessages,
    pageType,
    publicFeatureToggles,
    publicGlobalConfig,
    seoGlobalConfig,
    swrFallback,
  } = props

  /* eslint-disable react/jsx-max-depth -- app needs many providers */
  return (
    <GlobalConfigContextProvider publicGlobalConfig={publicGlobalConfig}>
      <I18nProvider
        locale={publicGlobalConfig.locale}
        localeMessages={localeMessages}
        timeZone={publicGlobalConfig.timeZone}
      >
        <DeviceDetectorContextProvider deviceInfo={deviceInfo}>
          <SeoGlobalConfigProvider value={seoGlobalConfig}>
            <ExpaContextProvider expa={expa}>
              <WebviewContextProvider value={isWebview}>
                <AdServerConfigurationContextProvider
                  adServerConfig={adServerConfig}
                  adServerTargeting={adServerTargeting}
                >
                  <FeatureToggleProvider featureToggles={publicFeatureToggles}>
                    <ExperimentsProvider
                      experiments={experiments}
                      pageType={pageType}
                    >
                      <CustomSwrConfig fallback={swrFallback}>
                        <SeoAppMetaAndLinkTags />
                        <SeoGlobalJsonLd />
                        <SeoBreadcrumbListJsonLd
                          breadcrumbs={breadcrumbs ?? undefined}
                        />
                        {children}
                        <ExperimentsActivateGlobalExperiments
                          pageType={pageType}
                        />
                        <GtmProvider
                          gtmData={gtmDataProps}
                          gtmId={gtmConfig.gtmId}
                        />
                        <SaeConversationalChat />
                      </CustomSwrConfig>
                    </ExperimentsProvider>
                  </FeatureToggleProvider>
                </AdServerConfigurationContextProvider>
              </WebviewContextProvider>
            </ExpaContextProvider>
          </SeoGlobalConfigProvider>
        </DeviceDetectorContextProvider>
      </I18nProvider>
    </GlobalConfigContextProvider>
  )
}
