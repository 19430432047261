import { createContext } from 'react'

import { type AdServerConfiguration } from '../../config/getAdServerConfiguration'
import { type AdServerTargeting } from '../../getAdServerTargeting'

import { type AdServerState } from './useAdServerState'

export type AdServerConfigurationContextValue = {
  adServerConfig: AdServerConfiguration
  adServerTargeting: AdServerTargeting
  getBanner: AdServerState['getBanner']
  initBanners: AdServerState['initBanners']
}

export const AdServerConfigurationContext =
  createContext<AdServerConfigurationContextValue | null>(null)
