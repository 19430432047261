import { Md5 } from 'ts-md5'

import {
  type ContextData,
  type MetadataContent,
  type PlacementDecision,
} from '@redteclab/api/clients/adserver-api'
import { type ClientSessionCustomerData } from '@redteclab/api/clients/bully'

import {
  clientSideCookieGet,
  clientSideCookieGetUserConsentForAdStorage,
  clientSideCookieSet,
} from '../cookies'
import { type PublicGlobalConfig } from '../global-config'
import { gtmDataLayerPushAdServerEvent } from '../gtm'

import {
  AD_BANNER_CONTAINER_DATA_ATTR,
  AD_BANNER_CONTENT_DATA_ATTR,
  AD_BANNER_HEIGHT_DATA_ATTR,
  AD_BANNER_LOCAL_STORAGE_USER_CLOSED_FLAG_KEY,
  AD_BANNER_STATUS_DATA_ATTR,
  AD_BANNER_TAGS_DATA_ATTR,
  AD_BANNER_WIDTH_DATA_ATTR,
  AD_SERVER_USER_ID_COOKIE,
} from './adServerConst'
import { adServerCookiesGetOrGenerateUserIdentity } from './adServerCookie'
import {
  type AdServerCommand,
  type AdServerSetupPreloadedSlot,
} from './model/adServerTypes'

const adServerSlotsPush = (command: AdServerCommand): void => {
  window._slots ??= []
  window._slots.push(command)
}

export const adServerSlotsPushSyncInterestCommand = (
  secondLevelCategories: string[],
): void => {
  adServerSlotsPush([
    'syncInterest',
    {
      visitedCategories: secondLevelCategories,
    },
  ])
}

export const adServerSlotsPushSetupPreloadedSlotsCommand = (
  adServerSetupPreloadedSlots: AdServerSetupPreloadedSlot[],
): void => {
  adServerSlotsPush(['setupPreloadedSlots', adServerSetupPreloadedSlots])
}

export const adServerSlotsPushLoadCommand = ({
  context,
  slots,
}: {
  context?: Partial<ContextData>
  slots: Element[]
}): void => {
  if (context) {
    adServerSlotsPush(['load', { context, slots }])
  } else {
    adServerSlotsPush(['load', { slots }])
  }
}

const adServerSlotsGetTargetingContext = (): ContextData => {
  return window._targeting.context
}

const adServerSlotsGetTargetingKeywords = (): string[] => {
  return window._targeting.keywords
}

const getBannerSize = (
  slot: HTMLElement,
): { height: number; width: number } | null => {
  const widthString = slot.getAttribute(AD_BANNER_WIDTH_DATA_ATTR)
  const heightString = slot.getAttribute(AD_BANNER_HEIGHT_DATA_ATTR)
  if (widthString && heightString) {
    const width = Number.parseInt(widthString, 10)
    const height = Number.parseInt(heightString, 10)
    if (!Number.isNaN(width) && !Number.isNaN(height)) {
      return { height, width }
    }
  }

  return null
}

export const AD_SERVER_ON_PLACED_EVENT = 'adserveronplaced'

const onSlotPlaced = (
  slot: HTMLElement,
  metadataContent: MetadataContent,
): void => {
  const event = new CustomEvent(AD_SERVER_ON_PLACED_EVENT, {
    detail: metadataContent,
  })

  slot.dispatchEvent(event)
}

export const adServerSlotsPushInitCommand = ({
  language,
  tenantAndEnv,
}: {
  language: PublicGlobalConfig['language']
  tenantAndEnv: PublicGlobalConfig['tenantAndEnv']
}): void => {
  const identity = adServerCookiesGetOrGenerateUserIdentity()

  adServerSlotsPush([
    'init',
    {
      cookieConsent: clientSideCookieGetUserConsentForAdStorage(),
      getContext: adServerSlotsGetTargetingContext,
      getKeywords: adServerSlotsGetTargetingKeywords,
      getSlotSize: getBannerSize,
      getSlotTags: (slot: HTMLElement): string[] | null | undefined =>
        slot.getAttribute(AD_BANNER_TAGS_DATA_ATTR)?.split(','),
      handleError: (): void => {
        // void
      },
      identity,
      language,

      mapPlacement: (
        slot: HTMLElement,
        placement: PlacementDecision | null,
      ): PlacementDecision | null => {
        if (!placement) {
          return null
        }

        const flightIds: string | null = localStorage.getItem(
          AD_BANNER_LOCAL_STORAGE_USER_CLOSED_FLAG_KEY,
        )

        const { flightId } = placement

        if (flightId && flightIds?.includes(`${flightId}`)) {
          return null
        }

        return placement
      },
      /**
       * if GTM `eventCallback` fails to fire the gtm event
       * It leads that the click url might not work
       *  So as a fallback, there is a setTimeout to trigger click event
       */
      onClick: async (metadataContent: MetadataContent): Promise<void> => {
        return new Promise((resolve) => {
          if (Object.keys(metadataContent).length > 0) {
            gtmDataLayerPushAdServerEvent('kev_click', metadataContent, resolve)
          }
        })
      },

      onImpression: (metadataContent: MetadataContent): void => {
        if (Object.keys(metadataContent).length > 0) {
          gtmDataLayerPushAdServerEvent('kev_impr', metadataContent)
        }
      },

      onPlaced: onSlotPlaced,

      onStatusChange: (slot: HTMLElement, status: string): void => {
        slot.setAttribute(AD_BANNER_STATUS_DATA_ATTR, status)
        if (status === 'ready') {
          /**
           * Finds the banner container and append the [data-as-content] attribute to the first element.
           * This attribute [data-as-content] is used to apply some style.
           */
          slot
            .querySelector(`[${AD_BANNER_CONTAINER_DATA_ATTR}]`)
            ?.firstElementChild?.setAttribute(AD_BANNER_CONTENT_DATA_ATTR, '')
        }
      },
      onView: (metadataContent: MetadataContent): void => {
        if (Object.keys(metadataContent).length > 0) {
          gtmDataLayerPushAdServerEvent('kev_view', metadataContent)
        }
      },
      tenant: tenantAndEnv,
    },
  ])
}

export const adServerSyncInterestCommandIsPushed = (): boolean => {
  return (
    window._slots?.some(([commandName]) => commandName === 'syncInterest') ??
    false
  )
}

/**
 * whenever user logs in, we hash customer number and push to adserver client.
 */
export const adServerSlotsPushSyncUserCommand = ({
  customerNumber,
}: ClientSessionCustomerData): void => {
  if (!customerNumber) {
    return
  }

  const crossEngageUserId = Md5.hashStr(customerNumber)

  clientSideCookieSet(AD_SERVER_USER_ID_COOKIE, crossEngageUserId)

  const userId = clientSideCookieGet(AD_SERVER_USER_ID_COOKIE)
  if (userId) {
    adServerSlotsPush([
      'syncUser',
      {
        userId,
      },
    ])
  }
}
