'use client'

import { type FormatXMLElementFn } from 'intl-messageformat'
import { type FC, type PropsWithChildren, type ReactNode } from 'react'
import {
  type IntlConfig,
  IntlProvider,
  MissingTranslationError,
} from 'react-intl'

import { apmCaptureError } from '../../../apm'
import { type Messages } from '../../i18nTypes'
import { type LOCALE } from '../../LOCALE'

import { customElements } from './customElements'
import { htmlElements } from './htmlElements'

const defaultRichTextElements: Record<string, FormatXMLElementFn<ReactNode>> = {
  ...htmlElements,
  ...customElements,
}

const onError: IntlConfig['onError'] = (error) => {
  if (process.env.NODE_ENV === 'production') {
    apmCaptureError(error)
  } else {
    /* eslint-disable no-console -- needed for developers */
    console.error(error)

    if (error instanceof MissingTranslationError) {
      console.warn(
        `Try run "yarn workspace app run compile-translations" to update locale files`,
      )
    }
    /* eslint-enable no-console */
  }
}

type LocaleProviderProps = PropsWithChildren<{
  locale: LOCALE
  localeMessages: Messages
  timeZone: string
}>

export const I18nProvider: FC<LocaleProviderProps> = ({
  children,
  locale,
  localeMessages,
  timeZone,
}) => (
  <IntlProvider
    defaultRichTextElements={defaultRichTextElements}
    locale={locale}
    messages={localeMessages}
    onError={onError}
    timeZone={timeZone}
  >
    {children}
  </IntlProvider>
)
