import { type FC, useEffect } from 'react'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { experimentsApiActivateClientSide } from '../api/experimentsApi'
import { ExperimentPageType } from '../experimentPageType'
import { EXPERIMENTS_TO_ACTIVATE_PER_PAGE } from '../model/EXPERIMENTS_TO_ACTIVATE_PER_PAGE'

import { useExperimentsContext } from './ExperimentsContext'

/**
 * Activate all available enabled experiments, to avoid manual activation.
 * For the cases, when activation should happen by click, experimentsApiActivateClientSide
 * function is available
 */
export const ExperimentsActivateGlobalExperiments: FC<{
  pageType?: string
}> = ({ pageType }) => {
  const { experiments } = useExperimentsContext()
  const { language, tenantAndEnv } = useGlobalConfigContext()

  useEffect(() => {
    if (!pageType) {
      return
    }

    const pageExperiments = EXPERIMENTS_TO_ACTIVATE_PER_PAGE[pageType] ?? []

    const experimentsToActivate = [
      ...pageExperiments,
      ...EXPERIMENTS_TO_ACTIVATE_PER_PAGE[ExperimentPageType.Default],
    ]
    const oldNames = experimentsToActivate.filter(
      (experimentName) => typeof experimentName === 'string',
    )
    const tenantBasedNames = experimentsToActivate.filter(
      (element) => typeof element !== 'string',
    )

    const experimentNames = new Set<string>(
      Object.values(tenantBasedNames).flatMap((item) => {
        return Object.values(item).flatMap((experiment) => {
          if (typeof experiment !== 'string') {
            return Object.values(experiment)
          }

          return [experiment, `${experiment}-m`]
        })
      }),
    )
    const oldAndTenantBasedExperimentNames = new Set([
      ...experimentNames,
      ...oldNames,
    ])

    const featureKeys = experiments
      .filter(
        (experiment) =>
          oldAndTenantBasedExperimentNames.has(experiment.name) &&
          experiment.isEnabled,
      )
      .map((experiment) => experiment.name)

    if (featureKeys.length > 0) {
      experimentsApiActivateClientSide({
        experiments: featureKeys,
        language,
        tenantAndEnv,
      })
    }
  }, [experiments, language, tenantAndEnv, pageType])

  return null
}
