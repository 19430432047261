import { LOCALE } from '../i18n'
import {
  TENANT,
  type TenantLanguage,
  type TenantLanguagesConfig,
} from '../tenant'

export const getTenantLocalesMap: () => {
  [tenant in TENANT]: Record<
    TenantLanguagesConfig[tenant]['languages'][number],
    LOCALE
  >
} = () => ({
  [TENANT.AT]: {
    de: LOCALE.DE_AT,
  },
  [TENANT.BE]: {
    fr: LOCALE.FR_BE,
    nl: LOCALE.NL_BE,
  },
  [TENANT.CH]: {
    de: LOCALE.DE_CH,
    fr: LOCALE.FR_CH,
  },
  [TENANT.COM]: {
    de: LOCALE.DE_DE,
  },
  [TENANT.FR]: {
    fr: LOCALE.FR_FR,
  },
  [TENANT.IT]: {
    it: LOCALE.IT_IT,
  },
})

export const getTenantLocale = (
  tenant: TENANT,
  language: TenantLanguage,
): LOCALE => {
  const localesByLanguage: Partial<Record<TenantLanguage, LOCALE>> =
    getTenantLocalesMap()[tenant]

  const locale = localesByLanguage[language]

  if (!locale) {
    throw new Error(
      `LOCALE is not supported for tenant "${tenant}" and language "${language}"`,
    )
  }

  return locale
}
