import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { type TENANT } from '../../tenant/tenantTypes'
import { EXPERIMENT_NAME_NEW_CDN } from '../model/EXPERIMENT_NAME'
import { type ExperimentType } from '../model/Experiment.types'

import { EXPERIMENTS_NEW_AKAMAI_URL_PER_TENANT_MAP } from './EXPERIMENTS_NEW_AKAMAI_URL_PER_TENANT_MAP'

/**
 * for serving file assets - e.g. pdfs, documents that user can download some images and similar
 *
 * we either use media server or new AKAMAI CDN
 * if experiment is NOT enabled return media server origin
 * if experiment is enabled returns akamai origin
 *
 * returns e.g. http://foo-bar.com
 */
export const useCdnStaticFileHost = (
  experiments: ExperimentType[],
  tenant: TENANT,
): string => {
  const { mediaServerUrl } = useGlobalConfigContext()
  const newCdnExperiment: ExperimentType | undefined = experiments.find(
    ({ name }) => name === EXPERIMENT_NAME_NEW_CDN,
  )
  let isExperimentEnabled = false

  if (newCdnExperiment) {
    const { isEnabled } = newCdnExperiment
    isExperimentEnabled = isEnabled
  }

  if (isExperimentEnabled) {
    return EXPERIMENTS_NEW_AKAMAI_URL_PER_TENANT_MAP[tenant]
  }

  return mediaServerUrl
}
