'use client'

import { type FC, type PropsWithChildren, useMemo } from 'react'
import { SWRConfig } from 'swr'

/**
 * in order that merging of swr fallback works between page navigations these settings are required:
 * revalidateIfStale: false,
 * revalidateOnFocus: false,
 * revalidateOnReconnect: false,
 * otherwise merging sets values and retriggers fetching for all queries.
 * this is not clearly documented in swr docs.
 * if this behaviour needed it shall be overriden in specific query.
 */
export const CustomSwrConfig: FC<
  PropsWithChildren<{
    fallback?: Record<string, unknown>
  }>
> = ({ children, fallback }) => {
  // needed for default if empty, so no new reference is created
  const memoizedFallBack = useMemo(() => fallback ?? {}, [fallback])

  return (
    <SWRConfig
      value={{
        fallback: memoizedFallBack,
        // required so for fallback merging to work
        revalidateIfStale: false,
        // required so for fallback merging to work
        revalidateOnFocus: false,
        // required so for fallback merging to work
        revalidateOnReconnect: false,
        // prevent DDoSing on failed requests, if expected shall be specified in query itself
        shouldRetryOnError: false,
      }}
    >
      {children}
    </SWRConfig>
  )
}
