/* eslint-disable @typescript-eslint/ban-ts-comment,@typescript-eslint/no-unsafe-call,no-bitwise,@typescript-eslint/no-magic-numbers */
// @ts-nocheck

import 'core-js/features/global-this'
import 'core-js/features/string/replace-all'

import coreJsExport from 'core-js/internals/export'

/**
 * Crypto is supported by all browsers from `app/.browserslistrc`.
 * https://developer.mozilla.org/en-US/docs/Web/API/Crypto#browser_compatibility
 *
 * But `randomUUID()` is new function. We use `core-js` functionality to apply
 * custom polyfill for `crypto` instance in browser.
 * https://github.com/zloirock/core-js/blob/master/packages/core-js/internals/export.js#L25
 */
coreJsExport(
  { stat: true, target: 'crypto' },
  {
    /**
     * https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid/2117523#2117523
     */
    randomUUID() {
      return '10000000-1000-4000-8000-100000000000'.replaceAll(
        /[018]/gu,
        (char) =>
          (
            char ^
            (globalThis.crypto.getRandomValues(new Uint8Array(1))[0] &
              (15 >> (char / 4)))
          ).toString(16),
      )
    },
  },
)
