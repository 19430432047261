// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
export const HTTP_HEADER_ACCEPT_LANGUAGE = 'accept-language'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization
export const HTTP_HEADER_AUTHORIZATION = 'authorization'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control
export const HTTP_HEADER_CACHE_CONTROL = 'cache-control'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type
export const HTTP_HEADER_CONTENT_TYPE = 'content-type'

export const HTTP_HEADER_RTL_TOKEN = 'rtl-token'

export const HTTP_HEADER_X_FEATURE_BRANCH = 'x-featurebranch'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-Host
export const HTTP_HEADER_X_FORWARDED_HOST = 'x-forwarded-host'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-Proto
export const HTTP_HEADER_X_FORWARDED_PROTO = 'x-forwarded-proto'

export const HTTP_HEADER_X_FORWARDED_URI = 'x-forwarded-uri'

export const HTTP_HEADER_X_UA_DEVICE = 'x-ua-device'
