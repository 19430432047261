import '../domain/polyfills'

import { type AppProps } from 'next/app'
import Head from 'next/head'
import { type FC } from 'react'

import { AppLayout, type AppPageProps } from '../domain/next-compose'
import { useHintHydrationReadyForEndToEndTest } from '../domain/testing-base'

import '../styles/globals.css'

const App: FC<AppProps<AppPageProps>> = (props) => {
  const { Component, pageProps } = props

  useHintHydrationReadyForEndToEndTest()

  return (
    <>
      <Head>
        <meta
          // `maximum-scale=1` prevents input focus zoom-in on iOS
          content="width=device-width, initial-scale=1, maximum-scale=1"
          name="viewport"
        />
      </Head>
      <AppLayout {...pageProps}>
        <Component {...pageProps} />
      </AppLayout>
    </>
  )
}

export default App
