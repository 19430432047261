import { TENANT } from '../../tenant/tenantTypes'

const NEW_CDN_STATIC_URL_BASE_COM = 'https://cdn.shop-apotheke.com'
const NEW_CDN_STATIC_URL_BASE_AT = 'https://cdn.shop-apotheke.at'
const NEW_CDN_STATIC_URL_BASE_FR = 'https://cdn.redcare-pharmacie.fr'
const NEW_CDN_STATIC_URL_BASE_CH = 'https://cdn.shop-apotheke.ch'
const NEW_CDN_STATIC_URL_BASE_IT = 'https://cdn.redcare.it'
const NEW_CDN_STATIC_URL_BASE_BE = 'https://cdn.farmaline.be'

export const EXPERIMENTS_NEW_AKAMAI_URL_PER_TENANT_MAP = {
  [TENANT.AT]: NEW_CDN_STATIC_URL_BASE_AT,
  [TENANT.BE]: NEW_CDN_STATIC_URL_BASE_BE,
  [TENANT.CH]: NEW_CDN_STATIC_URL_BASE_CH,
  [TENANT.COM]: NEW_CDN_STATIC_URL_BASE_COM,
  [TENANT.FR]: NEW_CDN_STATIC_URL_BASE_FR,
  [TENANT.IT]: NEW_CDN_STATIC_URL_BASE_IT,
}
