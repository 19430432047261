/**
 * `Intl` supported list of available locales
 */
export const LOCALE = {
  DE_AT: 'de-AT',
  DE_CH: 'de-CH',
  DE_DE: 'de-DE',
  FR_BE: 'fr-BE',
  FR_CH: 'fr-CH',
  FR_FR: 'fr-FR',
  IT_IT: 'it-IT',
  NL_BE: 'nl-BE',
} as const

// eslint-disable-next-line @typescript-eslint/naming-convention -- enum emulation
export type LOCALE = (typeof LOCALE)[keyof typeof LOCALE]
