'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

type AppRouterContextValue = { isAppRouter: boolean }

// Indicates component presence in App Router folder
export const AppRouterMigrationContext = createContext<AppRouterContextValue>({
  isAppRouter: false,
})

export const useAppRouterMigrationContext = (): AppRouterContextValue =>
  useContext(AppRouterMigrationContext)

const appRouterMigrationValue = {
  isAppRouter: true,
}

export const AppRouterMigrationProvider: FC<PropsWithChildren> = ({
  children,
}) => (
  <AppRouterMigrationContext.Provider value={appRouterMigrationValue}>
    {children}
  </AppRouterMigrationContext.Provider>
)
