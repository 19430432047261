import { type ClientRequest } from 'node:http'

import { type AxiosError, isAxiosError } from 'axios'

export const apmGetAxiosErrorCustomContext = (
  error: AxiosError,
): Record<string, unknown> => {
  const custom = {}

  if (isAxiosError<unknown>(error) && error.response) {
    Object.assign(custom, {
      data: error.response.data,
    })

    /*
     * Do not use `error.response.request instanceof ClientRequest` because code is shared across server & client
     */
    if (error.response.request) {
      const { host, path, protocol } = error.response.request as ClientRequest

      Object.assign(custom, {
        url: `${protocol}//${host}${path}`,
      })
    }
  }

  return custom
}
